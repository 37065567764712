import { createSlice } from "@reduxjs/toolkit";

const initialState = {
    data: [],
    dataId:[]
}

const dataSlice = createSlice({
    name: "data",
    initialState: initialState,
    reducers: {
        setData(state, action) {
            state.data = action.payload;
        },
        setDataId(state, action) {
            state.dataId=action.payload
        }
    },
});

export const { setData,setDataId } = dataSlice.actions;
export default dataSlice.reducer;