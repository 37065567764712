import React, { useEffect, useState } from "react";
import "./Sidebar.css";
import { UilSignOutAlt } from "@iconscout/react-unicons";
import { UilBars } from "@iconscout/react-unicons";
import { motion } from "framer-motion";
import { Link, useNavigate } from "react-router-dom";
import { useDispatch, } from "react-redux";
import { logout } from "../../../redux/operation/authApi";

const Sidebar = ({ active, setActive }) => {
    const navigate = useNavigate()
    const dispatch = useDispatch()
    const [expanded, setExpaned] = useState(true)
    const [act, setAct] = useState(true)

    const sidebarVariants = {
        true: {
            left: '0'
        },
        false: {
            left: '-60%'
        }
    }

    const handleClick = () => {
        dispatch(logout(navigate))
    }
    const handleClickDash = () => {
        setActive(1)
        setAct(true)
    }
    const handleClickDash2 = () => {
        setActive(2)
        setAct(false)
    }

    return (
        <>
            <div className="bars" style={expanded ? { left: '60%' } : { left: '5%' }} onClick={() => setExpaned(!expanded)}>
                <UilBars />
            </div>
            <motion.div className='sidebar'
                variants={sidebarVariants}
                animate={window.innerWidth <= 768 ? `${expanded}` : ''}
            >
                <div className="logo">
                    <span>
                        At<span>hnu</span>m
                    </span>
                </div>

                <div className="menu">
                    <div className={`menuItem ${act ? "active" : ""}`} onClick={handleClickDash}>

                        <div className="text-lg">Dashboard</div>

                    </div>
                    <div className={`menuItem w-full ${act ? "" : "active"}`} onClick={handleClickDash2}>
                        Member's
                    </div>
                    <div className="menuItem" onClick={handleClick} >
                        <UilSignOutAlt /> Logout
                    </div>
                </div>
            </motion.div>
        </>
    );
};

export default Sidebar;
