import { apiConnector } from "../apiConnector"
import { setData, setDataId } from "../slice/dataSlice"
// import { baseUrl } from "./api"


export const getAllData = async (token) => {
    const URL = "https://athnum.com/api/v1/getAll"
    // const URL = "http://localhost:3000/api/v1/getAll"
    let result = []
    try {
        const response = await apiConnector("GET", URL, null, {
            Authorization: `${token}`,
        })
        // console.log("response", response)
        result = response?.data?.data
        // console.log("result", result)
        setData(result)
    } catch (error) {
        console.log("GET_ALL_COURSE_API API ERROR............", error)
    }
    return result
}

export const getDataById = async (id, token) => {
    const URL = `https://athnum.com/api/v1/data/${id}`
    // const URL = `http://localhost:3000/api/v1/data/${id}`
    let result = []
    try {
        const response = await apiConnector("GET", URL, id, {
            Authorization: `${token}`,
        })
        // console.log("response", response)
        result = response?.data?.data
        // console.log("result", result)
        setDataId(result)
    } catch (error) {
        console.log("GET_COURSE_BY_ID_API ERROR............", error)
    }
    return result
}