import React, { useState } from 'react'
import { AiOutlineEye, AiOutlineEyeInvisible } from 'react-icons/ai'
// import axios from 'axios'
import { useNavigate } from 'react-router-dom'
import { useDispatch } from 'react-redux'
import { login } from '../../redux/operation/authApi'
import toast from 'react-hot-toast'


const Login = () => {
    const dispatch = useDispatch()
    const navigate = useNavigate()
    const [email, setEmail] = useState("")
    const [password, setpassword] = useState('')
    const [visible, setVisible] = useState(true)
    const handleSubmit = async (e) => {
        e.preventDefault()
        dispatch(login(email, password, navigate))
    }
    return (
        <div className='min-h-screen flex flex-col py-12 sm:px-6 lg:px-8'>
            <div className='sm:mx-auto sm:w-full sm:max-w-md mt-2'>
                <h2 className='mt-6 text-center text-2xl font-medium text-gray-900'>
                    LOG IN TO YOUR ACCOUNT
                </h2>
            </div>
            <div className='mt-8 mx-auto w-[85%] xl:w-[100%] sm:max-w-md'>
                <div className='bg-white     py-8 shadow sm:rounded rounded-2xl px-3'>
                    <form action="" className='space-y-8' onSubmit={handleSubmit}>
                        <div className='px-2'>
                            <label htmlFor="email" className='block text-sm font-medium text-gray-700 mt-2 mb-2'>Email address</label>
                            <div>
                                <input type="email" name='email' autoComplete='email' required value={email} onChange={(e) => setEmail(e.target.value)}
                                    className='appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-blue-500 focus:text-sm focus:border-blue-500' />
                            </div>
                        </div>
                        <div className='mt-3 relative px-2'>
                            <label htmlFor="password" className='block text-sm font-medium text-gray-700 mt-2 mb-2'>Password</label>
                            <div>
                                <input type={visible ? "text" : "password"} name='password' autoComplete='current-password' required value={password} onChange={(e) => setpassword(e.target.value)}
                                    className='appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-blue-500 focus:text-sm focus:border-blue-500' />
                                {
                                    visible ? (<AiOutlineEye className='absolute right-3 top-9 cursor-pointer'
                                        size={25} onClick={() => setVisible(false)} />) :
                                        (
                                            <AiOutlineEyeInvisible className='absolute right-3 top-7 cursor-pointer'
                                                size={25} onClick={() => setVisible(true)} />
                                        )
                                }
                            </div>
                        </div>
                        {/* <div className={`justify-between`}>
                            <div className='text-sm'>
                                <a href=".forget-password" className='font-medium text-blue-600 focus:text-blue-500'>Forget your password</a>
                            </div>
                        </div> */}
                        <div>
                            <button type='submit'
                                className='group relative w-full h-[40px] flex justify-center py-2 px-4 border border-transparent text-sm font-medium rounded-md text-white bg-blue-600 hover:bg-blue-700 mb-3'>
                                Submit
                            </button>
                        </div>
                    </form>
                </div>
            </div>
        </div>
    )
}

export default Login