import axios from 'axios'
import React, {useState } from 'react'
import toast from 'react-hot-toast'
import { useNavigate } from 'react-router-dom'
// import Aos from 'aos'
import 'aos/dist/aos.css'
import { NatureBusiness, PassingYear } from '../../data'

const Form = () => {

    const [disk, setDisk] = useState('hidden')
    const navigate = useNavigate()
    const URL = "https://athnum.com/api/v1"
    const [formData, setFormData] = useState({
        firstName: "", lastName: "", husbandName: "", motherName: "", email: "", phoneNumber: "", date: "", gender: "", married: "", boy: "", girl: "", img: "", education: "", panNumber: "",
        adhaarNumber: "", house: "", address: "", pinCode: "", permanentResidentalAddress: "", permanentResidentalPin: "", salaried: false,
        selfEmployeed: false, cash: false, cheque: false, online: false, monthIncome: "", businessName: "", businessRegisterAdd: "", businessRegisterPin: "",
        annualTurnOver: "", registerationType: "", natureBusiness: "", amount: "", howManyBaby: "", passingYear: ""
    })

    const { firstName, lastName, husbandName, email, phoneNumber, motherName, date, gender, married, boy, girl, img, education, panNumber, adhaarNumber, house
        , address, pinCode, permanentResidentalAddress, permanentResidentalPin, salaried, cash, cheque, online, monthIncome, businessName,
        businessRegisterAdd, businessRegisterPin, annualTurnOver, registerationType, natureBusiness, amount, howManyBaby, passingYear, selfEmployeed } = formData
    const handleChange = (e) => {
        const { name, value, checked, type } = e.target;
        setFormData((prev) => ({ ...prev, [name]: type === "checkbox" ? checked : value }));
    }

    const [correct1, setCorrect1] = useState(false)
    const [correct2, setCorrect2] = useState(false)
    const [correct3, setCorrect3] = useState(false)
    const [correct4, setCorrect4] = useState(false)
    const [correct5, setCorrect5] = useState(false)
    const handleInput = (e) => {
        if (e.target.value === "Married") {
            setDisk('block')
        } else {
            setDisk('hidden')
        }
    }
    const handleClick1 = () => {
        setCorrect1(true)
        setCorrect2(true)
    }
    const handlePrev2 = () => {
        setCorrect1(false)
        setCorrect2(false)
    }
    const handleClick2 = () => {
        setCorrect3(true)
        setCorrect2(false)
    }
    const handlePrev3 = () => {
        setCorrect2(true)
        setCorrect3(false)
    }
    const handleClick3 = () => {
        setCorrect4(true)
        setCorrect3(false)
    }
    const handlePrev4 = () => {
        setCorrect3(true)
        setCorrect4(false)
    }
    const handleClick4 = () => {
        setCorrect5(true)
        setCorrect4(false)
    }
    const handlePrev5 = () => {
        setCorrect4(true)
        setCorrect5(false)

    }
    const handleSubmit = async (e) => {
        e.preventDefault()
        const res = await axios.post(`${URL}/post`, formData)
        if (res) {
            toast.success("Filled Successfully")
            navigate('/')
        } else {
            toast.error("Error")
        }
    }

    // useEffect(() => {
    //     Aos.init()
    // }, [])
    return (
        < div className='bg-[#efefef]'>
            <div className='xl:w-[50%] md:w-[50%] w-full flex flex-col m-auto'>
                <div>
                    <div className='xl:pl-[80px] md:pl-[60px] pl-[2px]'>
                        <ul className='divul flex items-center justify-center w-full mt-3 xl:mb-4 mb-12'>
                            <li className={`xl:w-[23%] w-[23%] h-[1px] ${correct1 ? "bg-[#679b9b]" : "bg-[#ff9a76]"} mt-3 relative items-start justify-center`}>
                                <p className={`absolute top-[-13px] px-[7px] ${correct1 ? "bg-[#679b9b]" : "bg-[#ff9a76]"} text-white font-[600]`}>1</p>
                                <p className={` ${correct1 ? "text-[#679b9b]" : "text-[#ff9a76]"} mt-3 mr-10 xl:text-[13px] text-[10px] absolute xl:left-[-23px]`}> Personal Detail</p>
                            </li>
                            <li className={`xl:w-[24%] w-[23%] h-[1px] ${correct2 ? "bg-[#ff9a76]" : "bg-[#679b9b]"} mt-3 relative items-start justify-center`}>
                                <p className={`absolute top-[-13px] px-[7px] ${correct2 ? "bg-[#ff9a76]" : "bg-[#679b9b]"} text-white font-[600]`}>2</p>
                                <p className={`${correct2 ? "text-[#ff9a76]" : "text-[#679b9b]"} mt-3 mr-10 xl:text-[13px] text-[10px] absolute xl:left-[-23px]`}> Education Detail</p>
                            </li>
                            <li className={`xl:w-[25%] w-[23%] h-[1px] ${correct3 ? "bg-[#ff9a76]" : "bg-[#679b9b]"} mt-3 relative items-start justify-center`}>
                                <p className={`absolute top-[-13px] px-[7px] ${correct3 ? "bg-[#ff9a76]" : "bg-[#679b9b]"} text-white font-[600]`}>3</p>
                                <p className={`${correct3 ? "text-[#ff9a76]" : "text-[#679b9b]"} mt-3 mr-10 xl:text-[13px] text-[10px] absolute xl:left-[-23px]`}> Residental Detail</p>
                            </li>
                            <li className={`xl:w-[27%] w-[23%] h-[1px] ${correct4 ? "bg-[#ff9a76]" : "bg-[#679b9b]"} mt-3 relative items-start justify-center`}>
                                <p className={`absolute top-[-13px] px-[7px] ${correct4 ? "bg-[#ff9a76]" : "bg-[#679b9b]"} text-white font-[600]`}>4</p>
                                <p className={` ${correct4 ? "text-[#ff9a76]" : "text-[#679b9b]"} mt-3 mr-10 xl:text-[13px] text-[10px] absolute xl:left-[-23px]`}> Employment Detail</p>
                            </li>
                            <li className={`xl:w-[28%] w-[23%] ${correct5 ? "bg-[#ff9a76]" : "bg-[#679b9b]"} mt-3 relative items-start justify-center`}>
                                <p className={`absolute top-[-13px] px-[7px] ${correct5 ? "bg-[#ff9a76]" : "bg-[#679b9b]"} text-white font-[600]`}>5</p>
                                <p className={`${correct5 ? "text-[#ff9a76]" : "text-[#679b9b]"} mt-3 mr-10 xl:text-[13px] text-[10px] absolute xl:left-[-23px]`}> My Investment Plan</p>
                            </li>
                        </ul>
                    </div>
                </div>
                <div >
                    <form action="" className='xl:px-2 md:mt-6 xl:mt-10 mt-10 mb-10' onSubmit={handleSubmit}  >
                        {/* FIELDSET 1 */}
                        <fieldset className={`w-full flex flex-col justify-center gap-4 bg-white ${correct1 ? "hidden" : "block"} border rounded-lg px-[20px] xl:px-[2.9rem] md:px-[2.5rem] py-6 shadow-lg`}>
                            <h2 className='text-[30px] font-semibold '>PERSONAL DETAILS</h2>
                            <label>
                                <input type="text" placeholder='First Name' name='firstName' value={firstName} className='border xl:w-[100%] md:w-[100%] w-[100%] px-3 py-3' onChange={handleChange} required />
                            </label>
                            <label htmlFor="">
                                <input type="text" placeholder='Last Name' name='lastName' value={lastName} className='border xl:w-[100%] md:w-[100%] w-[100%] px-3 py-3' onChange={handleChange} />
                            </label>
                            <label htmlFor="">
                                <input type="text" placeholder="Email Address" name='email' value={email} className='border xl:w-[100%] md:w-[100%] w-[100%] px-3 py-3' onChange={handleChange} />
                            </label>
                            <label htmlFor="">
                                <input type="text" placeholder="Phone Number" name='phoneNumber' value={phoneNumber} className='border xl:w-[100%] md:w-[100%] w-[100%] px-3 py-3' onChange={handleChange} />
                            </label>
                            <label htmlFor="">
                                <input type="text" placeholder="Husband/Father's Name" name='husbandName' value={husbandName} className='border xl:w-[100%] md:w-[100%] w-[100%] px-3 py-3' onChange={handleChange} />
                            </label>
                            <label htmlFor="">
                                <input type="text" placeholder="Mother's Name" name='motherName' value={motherName} className='border xl:w-[100%] md:w-[100%] w-[100%] px-3 py-3' onChange={handleChange} />
                            </label>
                            <label htmlFor="" className='relative'>
                                <input type="Date" name='date' value={date} placeholder='date' className='border xl:w-[100%] md:w-[100%] w-[100%] px-3 py-3 ' onChange={handleChange} />
                                <span className='block md:hidden xl:hidden top-4 absolute left-5 text-[16px]'>Date Of Birth</span>
                            </label>
                            <label htmlFor="">
                                <select name="gender" id="gender" className='border xl:w-[100%] md:w-[100%] w-[100%] px-3 py-3' value={gender} onChange={handleChange}>
                                    <option >Gender</option>
                                    <option>Male</option>
                                    <option>Female</option>
                                </select>
                            </label>
                            <label htmlFor="" >
                                <select name="married" className="border xl:w-[100%] md:w-[100%] w-[100%] px-3 py-3" value={married} onChange={handleChange} onClick={handleInput} >
                                    <option className='mx-10'>Married Status</option>
                                    <option>Married</option>
                                    <option>UnMarried</option>
                                    {/* {option.map(option => (
                                        <option value={option.label}>{option.label}</option>
                                    ))} */}
                                </select>
                                {/* {option.label} */}
                            </label>
                            <fieldset className={`${disk} flex flex-col gap-4`}>
                                <label htmlFor="">
                                    <select name="howManyBaby" id="howManyBaby" className='border xl:w-[100%] md:w-[100%] w-[100%] px-3 py-3 ' value={howManyBaby} onChange={handleChange} >
                                        <option>How Many Babies!</option>
                                        <option>0</option>
                                        <option>1</option>
                                        <option>2</option>
                                        <option>4</option>
                                        <option>5</option>
                                    </select>
                                </label>

                                <label htmlFor="">
                                    <select name="boy" id="boy" className='border xl:w-[100%] md:w-[100%] w-[100%] px-3 py-3 ' value={boy} onChange={handleChange}>
                                        <option>Boy</option>
                                        <option >0</option>
                                        <option>1</option>
                                        <option>2</option>
                                        <option>3</option>
                                        <option>4</option>
                                        <option>5</option>
                                    </select>
                                </label>
                                <label htmlFor="">
                                    <select name="girl" id="girl" className='border xl:w-[100%] md:w-[100%] w-[100%] px-3 py-3' value={girl} onChange={handleChange}>
                                        <option>Girl</option>
                                        <option>0</option>
                                        <option>1</option>
                                        <option>2</option>
                                        <option>3</option>
                                        <option>4</option>
                                        <option>5</option>
                                    </select>
                                </label>
                            </fieldset>
                            <label htmlFor="" className='text-[13.3px]'><p className=''>Upload Selfie photo (png, jpg), file size – 1 Mb maximum</p>
                                <input type="file" accept='.jpg,.jpeg,.png' name='img' value={img} placeholder='Choose a file' className='border xl:w-[100%] md:w-[100%] w-[100%] px-3 py-3 mt-2 text-[16px]' onChange={handleChange} />
                            </label>
                            <div>
                                <input type="button" value="Next" className="bg-[#ff9a76] text-white font-[500] text-[15px] px-8 py-2" onClick={handleClick1} onChange={handleChange} />
                            </div>
                        </fieldset>
                        {/* FIELDSET 2 */}
                        <fieldset className={`w-full overflow-x-hidden flex flex-col gap-4 justify-center bg-white ${correct2 ? "block" : "hidden"} border rounded-lg px-[20px] xl:px-[2.9rem] md:px-[2.5rem] py-6 shadow-lg overflow-hidden`}>
                            <h2 className='text-[30px] font-semibold'>EDUCATION DETAILS</h2>
                            <label htmlFor="">
                                <select name="education" id="education" className='border xl:w-[100%] md:w-[100%] w-[100%] px-3 py-3' value={education} onChange={handleChange}>
                                    <option>Education</option>
                                    <option>10+</option>
                                    <option>12+</option>
                                    <option>Under Graduated</option>
                                    <option>Post Graduated</option>
                                    <option>Master</option>
                                </select>
                            </label>
                            <label htmlFor="">
                                <select name="passingYear" id="passing-year" className='border xl:w-[100%] md:w-[100%] w-[100%] px-3 py-3' value={passingYear} onChange={handleChange}>
                                    <option>Passing Year</option>
                                    {
                                        PassingYear.map((date, index) => (
                                            <option key={index}>{date}</option>
                                        ))
                                    }
                                </select>
                            </label>
                            <label htmlFor="" className='text-[13.3px]'><p className='mb-2'>Upload PAN card (png, jpg, pdf format),file size – 1 Mb maximum</p>
                                <input type="text" placeholder='PAN card number' className='border xl:w-[100%] md:w-[100%] w-[100%] px-3 py-3 text-[15px]' name='panNumber' value={panNumber} onChange={handleChange} />
                            </label>
                            <label htmlFor="" className='text-[13.3px]'>
                                <p className='mb-2'> Upload Adhaar card (png, jpg, pdf format), file size – 1 Mb maximum</p>
                                <input type="text" placeholder='Adhaar number' className='border xl:w-[100%] md:w-[100%] w-[100%] px-3 py-3 text-[15px]' name='adhaarNumber' value={adhaarNumber} onChange={handleChange} />
                            </label>
                            <div className='flex gap-4'>
                                <input type="button" value="Previous" className={`bg-[#ff9a76] text-white font-[500] text-[15px] px-8 py-2 `} onClick={handlePrev2} />
                                <input type="button" value="Next" className="bg-[#ff9a76] text-white font-[500] text-[15px] px-8 py-2" onClick={handleClick2} />
                            </div>
                        </fieldset>
                        {/* FIELD SET 3 */}
                        <fieldset className={`flex flex-col justify-center bg-white gap-4 border rounded-lg ${correct3 ? "block" : "hidden"} px-[20px] md:px-[2.5rem] xl:px-[2.9rem] py-6 shadow-lg overflow-hidden`} >
                            <h2 className='text-[30px] font-semibold'>RESIDENTAL DETAILS</h2>
                            <fieldset>

                                <input type="radio" className='mr-2' name='house' value="ownHouse" onChange={handleChange} />
                                Own House


                                <input type="radio" className='ml-5 mr-2' name='house' value="rentedHouse" onChange={handleChange} />
                                Rented House

                            </fieldset>
                            <label htmlFor="">
                                <input type="text" placeholder='Your Address' className='border xl:w-[100%] md:w-[100%] w-[100%] px-3 py-3 text-[15px]' name='address' value={address} onChange={handleChange} />
                            </label>
                            <label htmlFor="">
                                <input type="text" placeholder='Pin Code' className='border xl:w-[100%] md:w-[100%] w-[100%] px-3 py-3 text-[15px]' name='pinCode' value={pinCode} onChange={handleChange} />
                            </label>
                            <label htmlFor="">
                                <input type="text" placeholder='Permanent Residental Address' className='border xl:w-[100%] md:w-[100%] w-[100%] px-3 py-3 text-[15px]' name='permanentResidentalAddress' value={permanentResidentalAddress} onChange={handleChange} />
                            </label>
                            <label htmlFor="">
                                <input type="text" placeholder='Permanent Residental Pin Code' className='border xl:w-[100%] md:w-[100%] w-[100%] px-3 py-3 text-[15px]' name='permanentResidentalPin' value={permanentResidentalPin} onChange={handleChange} />
                            </label>
                            <div className='flex gap-4'>
                                <input type="button" value="Previous" className={`bg-[#ff9a76] text-white font-[500] text-[15px] px-8 py-2`} onClick={handlePrev3} />
                                <input type="button" value="Next" className="bg-[#ff9a76] text-white font-[500] text-[15px] px-8 py-2" onClick={handleClick3} />
                            </div>
                        </fieldset>
                        {/* FIELDSET4 */}

                        <fieldset
                            className={`flex flex-col justify-center gap-4 border rounded-lg bg-white ${correct4 ? "block" : "hidden"} px-[20px] xl:px-[2.9rem] md:px-[2.5rem] py-6 shadow-lg overflow-x-hidden`}>
                            <h2 className='text-[30px] font-semibold'>EMPLOYEMENT DETAILS</h2>
                            <div className='flex flex-col gap-4'>Your Employment type :
                                <label htmlFor="">
                                    <input type="checkbox" className='mr-2' checked={salaried} name='salaried' onChange={handleChange} />Salaried
                                </label>
                                <label htmlFor="">
                                    <input type="checkbox" className='mr-2' checked={selfEmployeed} name='selfEmployeed' onChange={handleChange} />Self-employed
                                </label>
                            </div>
                            <div className='flex flex-col gap-4'>How do you get your Salary?
                                <label htmlFor="">
                                    <input type="checkbox" className='mr-2' name='online' checked={online} onChange={handleChange} />Online
                                </label>
                                <label htmlFor="">
                                    <input type="checkbox" className='mr-2' name='cheque' checked={cheque} onChange={handleChange} />Cheque
                                </label>
                                <label htmlFor="">
                                    <input type="checkbox" className='mr-2' name='cash' checked={cash} onChange={handleChange} />Cash
                                </label>
                            </div>
                            <label htmlFor="">
                                <input type="text" placeholder='Monthly Income' className='border xl:w-[100%] md:w-[100%] w-[100%] px-3 py-3' name='monthIncome' value={monthIncome} onChange={handleChange} />
                            </label>
                            <label htmlFor="">
                                <input type="text" placeholder='Business Name' className='border xl:w-[100%] md:w-[100%] w-[100%] px-3 py-3' name='businessName' value={businessName} onChange={handleChange} />
                            </label>
                            <label htmlFor="">
                                <input type="text" placeholder='Registered Address' className='border xl:w-[100%] md:w-[100%] w-[100%] px-3 py-3' name='businessRegisterAdd' value={businessRegisterAdd} onChange={handleChange} />
                            </label>
                            <label htmlFor="">
                                <input type="text" placeholder='Pin Code' className='border xl:w-[100%] md:w-[100%] w-[100%] px-3 py-3' name='businessRegisterPin' value={businessRegisterPin} onChange={handleChange} />
                            </label>
                            <label htmlFor="">
                                <select name="annualTurnOver" id="annual" className='border xl:w-[100%] md:w-[100%] w-[100%] px-3 py-3' value={annualTurnOver} onChange={handleChange}>
                                    <option>Annual Turn over</option>
                                    <option>Less then 20 lakh</option>
                                    <option>20lakh - 30Lakh</option>
                                    <option>30lakh - 40lakh</option>
                                    <option>40lakh - 50lakh</option>
                                    <option>50lakh - 60lakh</option>
                                    <option>60lakh - 70lakh</option>
                                    <option>Greater then 70 lakh</option>
                                </select>
                            </label>
                            <label htmlFor="">
                                <select name="registerationType" id="registeration-type" className='border xl:w-[100%] md:w-[100%] w-[100%] px-3 py-3' value={registerationType} onChange={handleChange}>
                                    <option>Registeration type</option>
                                    <option>Type 1</option>
                                    <option>Type 2</option>
                                    <option>TYpe 3</option>
                                </select>
                            </label>
                            <label htmlFor="">
                                <select name="natureBusiness" id="nature" className='border xl:w-[100%] md:w-[100%] w-[100%] px-3 py-3' value={natureBusiness} onChange={handleChange}>
                                    <option>Nature Of business</option>
                                    {
                                        NatureBusiness.map((data, index) => (
                                            <option key={index}>{data}</option>
                                        ))
                                    }
                                </select>
                            </label>
                            <div className='flex gap-4'>
                                <input type="button" value="Previous" className={`bg-[#ff9a76] text-white font-[500] text-[15px] px-8 py-2 `} onClick={handlePrev4} />
                                <input type="button" value="Next" className="bg-[#ff9a76] text-white font-[500] text-[15px] px-8 py-2" onClick={handleClick4} />
                            </div>
                        </fieldset>
                        {/* FIELDSET 5 */}
                        <fieldset
                            className={`w-full flex flex-col justify-center gap-5 border rounded-lg px-[20px] xl:px-[2.9rem] md:px-[2.5rem] py-6 bg-white ${correct5 ? "block" : "hidden"} shadow-lg overflow-x-hidden`}>
                            <h2 className='text-[22px] font-semibold'>MY INVESTMENT PLAN</h2>
                            <h2>Monthly Investment: Minimum= 500 & Maximum = 2000 only</h2>
                            <label htmlFor="">
                                <p>After 30 days return (show), 10% yearly interest rate</p>
                                <input type="text" placeholder='Enter Amount' className='border xl:w-[100%] md:w-[100%] w-[100%] px-3 py-3 mt-2' name='amount' value={amount} onChange={handleChange} />
                            </label>
                            <div className='flex gap-4'>
                                <input type="button" value="Previous" className={`bg-[#ff9a76] text-white font-[500] text-[15px] px-8 py-2 `} onClick={handlePrev5} />
                                <button type='submit' className='bg-[#ff9a76] text-white font-[500] text-[15px] px-8 py-2'>Submit</button>
                            </div>
                        </fieldset>
                    </form>
                </div>
                {/* </div> */}
            </div>
        </div >
    )
}

export default Form