import React from 'react'
import { useSelector } from 'react-redux'
import { Navigate } from 'react-router-dom';


const ProtectedRoute = ({ children }) => {

    const { token } = useSelector((state) => state.auth);
    console.log("token", token)
    if (token !== null)
        return children
    else
        return <Navigate to="/" />
}

export default ProtectedRoute