import { Route, Routes } from 'react-router-dom';
import './App.css';
import Login from './components/login/Login';
import NavBar from './components/navbar/Navbar';
import Form from './components/form/Form';
// import Page from './components/admin/Page';
import ProtectedRoute from './components/ProtectedRoute/ProtectedRoute.jsx';
import Admin from './components/AdminPanel/Admin/Admin.jsx';
import VIewAll from './components/AdminPanel/ViewAll/VIewAll.jsx';
// import FullDetail from './components/admin/FullDetail.jsx';


function App() {

  return (
    <div className="">
      <NavBar />
      <Routes>
        <Route path='/' element={<Form />} />
        <Route path='/login' element={<Login />} />
        <Route path='/admin' element={
          <ProtectedRoute>
            <Admin />
          </ProtectedRoute>} />
        <Route path='/data/:id' element={ <ProtectedRoute>   <VIewAll />   </ProtectedRoute> } />
      </Routes>
    </div>
  );
}

export default App;
