import React from 'react'
import { Link } from 'react-router-dom'

const Member = ({ item }) => {
    return (
        <div className="p-3 h-screen mr-10">
            <h1 className="text-2xl mb-4">Members</h1>

            <div className="overflow-auto rounded-lg shadow hidden md:block">
                <table className="w-full">
                    <thead className="bg-gray-50 border-b-2 border-gray-200">
                        <tr>
                            <th className="w-5 p-3 text-sm font-semibold tracking-wide text-left">Id</th>
                            <th className="w-20 text-sm font-semibold tracking-wide text-left p-2">Name</th>
                            <th className="w-20 p-3 text-sm font-semibold tracking-wide text-left">Phne Number</th>
                            <th className="w-20 p-3 text-sm font-semibold tracking-wide text-left">Email</th>
                            <th className="w-20 p-3 text-sm font-semibold tracking-wide text-left">Investment</th>
                            <th className="w-20 p-3 text-sm font-semibold tracking-wide text-left">Detail</th>
                        </tr>
                    </thead>
                    <tbody class="divide-y divide-gray-100">
                        {
                            item && item.map((data) => (
                                <tr className="bg-white" key={data.id}>
                                    <td className="p-3 text-sm text-gray-700 whitespace-nowrap">{data.id}</td>
                                    <td className="p-2 text-sm text-gray-700 whitespace-nowrap">{data.firstName} {data.lastName}</td>
                                    <td className="p-3 text-sm text-gray-700 whitespace-nowrap">{data.phoneNumber}</td>
                                    <td className="p-3 text-sm text-gray-700 whitespace-nowrap">{data.email}</td>
                                    <td className="p-3 text-sm text-gray-700 whitespace-nowrap">{data.amount}</td>
                                    <Link to={`/data/${data.id}`}><td className="p-3 text-sm whitespace-nowrap text-[blue]" target="_blank">View Detail</td></Link>
                                </tr>
                            ))
                        }
                    </tbody>
                </table>
            </div>

            <div className="grid grid-cols-1 sm:grid-cols-2 gap-4 md:hidden w-[130%] max-w-maxContent">
                {
                    item && item.map((data) => (
                        <div className="bg-white space-y-1 p-4 rounded-lg shadow" key={data.id}>
                            <div className="flex items-center space-x-2 text-sm">
                                <div>
                                    <a href="#" className="text-blue-500 font-bold hover:underline">{data.firstName}</a>
                                </div>
                                <div className="text-gray-500">{data.lastName}</div>
                                <div>
                                    <Link to={`/data/${data.id}`}> <span
                                        className="p-1.5 w-full max-w-max text-xs font-medium uppercase tracking-wider text-green-800 bg-green-200 rounded-lg bg-opacity-50">Detail</span></Link>
                                </div>
                            </div>
                            <div className="text-sm text-gray-700">
                                {data.phoneNumber}
                            </div>
                            <div className="text-sm font-medium text-black">
                                {data.email}
                            </div>
                            <div>Investment:
                                {data.amount}
                            </div>
                        </div>
                    ))
                }

            </div>
        </div>
    )
}

export default Member