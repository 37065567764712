import React from "react";
// import Table from "@mui/material/Table";
// import TableBody from "@mui/material/TableBody";
// import TableCell from "@mui/material/TableCell";
// import TableContainer from "@mui/material/TableContainer";
// import TableHead from "@mui/material/TableHead";
// import TableRow from "@mui/material/TableRow";
// import Paper from "@mui/material/Paper";
// import "./Table.css";
// import FullDetail from "../../admin/FullDetail";

// import { IndeterminateCheckBoxOutlined } from "@mui/icons-material";
// import { useParams } from "react-router-dom";


import { Box } from "@mui/material";
import { DataGrid, GridToolbar } from "@mui/x-data-grid";
import { tokens } from "../../../theme";
import Header from "./Header";
import { useTheme } from "@mui/material";




export default function BasicTable({ item }) {

    const theme = useTheme();
    const colors = tokens(theme.palette.mode);

    const columns = [
        { field: "id", headerName: "ID", width: 20 },
        {
            field: "firstName",
            headerName: "Name",
            cellClassName: "name-column--cell",
            width: 150
        },
        {
            field: "email",
            headerName: "Email",
            type: "number",
            headerAlign: "left",
            align: "left",
            width: 180
        },
        {
            field: "phoneNumber",
            headerName: "Phone Number",
            width: 120
        },
        {
            field: "panNumber",
            headerName: "Pan Number",
            width: 120
        },
        {
            field: "adhaarNumber",
            headerName: "Adhaar Number",
            width: 140
        },
        {
            field: "monthIncome",
            headerName: "Monthly Income",
            width: 130
        }, {
            field: "amount",
            headerName: "Investment",
        },
    ];
    return (
        <div className="w-full mr-5">
            <Box m="20px" sx={{ width: '100%' }}>
                <Header
                    title="PEOPLE"
                    subtitle="List of People's"
                />
                <Box
                    m="40px 0 0 0"
                    height="75vh"
                    sx={{
                        "& .MuiDataGrid-root": {
                            border: "none",
                        },
                        "& .MuiDataGrid-cell": {
                            borderBottom: "none",
                        },
                        "& .name-column--cell": {
                            color: colors.greenAccent[300],
                        },
                        "& .MuiDataGrid-columnHeaders": {
                            backgroundColor: colors.blueAccent[700],
                            borderBottom: "none",
                        },
                        "& .MuiDataGrid-virtualScroller": {
                            backgroundColor: colors.primary[400],
                        },
                        "& .MuiDataGrid-footerContainer": {
                            borderTop: "none",
                            backgroundColor: colors.blueAccent[700],
                        },
                        "& .MuiCheckbox-root": {
                            color: `${colors.greenAccent[200]} !important`,
                        },
                        "& .MuiDataGrid-toolbarContainer .MuiButton-text": {
                            color: `${colors.grey[100]} !important`,
                        },
                    }}
                >
                    <DataGrid
                        rows={item}
                        columns={columns}
                        components={{ Toolbar: GridToolbar }}
                    />
                </Box>
            </Box>
        </div>
    );
}

