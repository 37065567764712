import React from "react";
import Table from "../Table/Table";
import "./MainDash.css";
import Member from "../Member/Member";

const MainDash = ({ item, active }) => {

    return (
        <div className="MainDash">
            <h1 className="text-2xl mt-2">Dashboard</h1>
            {
                active === 2 && <Member item={item} />
            }
            {
                active === 1 && <Table item={item} />
            }
        </div>
    );
};

export default MainDash;
