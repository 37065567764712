import React, { useEffect, useState } from 'react'
import './Admin.css'
import Sidebar from '../Sidebar/Sidebar'
import MainDash from '../Main/MainDash'
import { useSelector } from 'react-redux'
import { getAllData } from '../../../redux/operation/dataApi'

const Admin = () => {
    const [active, setActive] = useState(1)
    const { token } = useSelector(state => state.auth)
    const [item, setItem] = useState([])
    useEffect(() => {
        const fetchDetail = async () => {
            const result = await getAllData(token)
            if (result) {
                setItem(result)
            }
        }
        fetchDetail()
    }, [])
// console.log("item", item)
    return (
        <div className="App">
            <div className="AppGlass">
                <Sidebar active={active} setActive={setActive} />
                <MainDash item={item} active={active} />
            </div>
        </div>
    )
}

export default Admin