import React, { useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'
import { getDataById } from '../../../redux/operation/dataApi'
import { useSelector } from 'react-redux'

const VIewAll = () => {
    const { token } = useSelector((state) => state.auth)
    const [data, setData] = useState([])
    const { id } = useParams()
    useEffect(() => {
        const fetchData = async () => {
            const response = await getDataById(id, token)
            if (response) {
                setData(response)
            } else {
                console.log("Error")
            }
        }
        fetchData()
    }, [])
    return (
        <div className='bg-white mt-4 mb-5'>
            <div className='flex flex-col gap-4 xl:w-[80%] xl:m-auto border rounded-xl p-4 shadow-xl xs:mx-2'>
                <h2 className='text-3xl mt-3 font-semibold text-center capitalize'>{data.firstName} {data.lastName}</h2>
                <p className='text-lg'><span className='text-md font-semibold text-end'>Investment :</span> {data.amount}</p>
                <div className='flex items-center'>
                    <div className='w-full bg-black h-[1px]'></div>
                    <div className='w-full max-w-max text-xl font-semibold'>PERSONAL DETAIL</div>
                    <div className='w-full bg-black h-[1px]'></div>
                </div>
                <div className='flex flex-col gap-2'>
                    <div className='grid grid-cols-1 md:grid-cols-2 xl:grid-cols-3'>
                        <p><span className='text-md font-semibold'> Phone Number:</span> {data.phoneNumber}</p>
                        <p><span className='text-md font-semibold'>Email Id :</span> {data.email}</p>
                        <p><span className='text-md font-semibold'>DOB: </span> {data.date}</p>
                    </div>
                    <div className='grid grid-cols-1 md:grid-cols-2 xl:grid-cols-3 '>
                        <p><span className='text-md font-semibold'>Gender :</span>{data.gender}</p>
                        <p><span className='text-md font-semibold'> Husband/Father Name: </span>{data.husbandName}</p>
                        <p> <span className='text-md font-semibold'>Mother Name :</span>{data.motherName}</p>
                    </div>
                    <div>
                        {
                            data.married === "Married" ? (
                                <div className='grid grid-cols-1 md:grid-cols-2 xl:grid-cols-4'>
                                    <p>  <span className='text-md font-semibold'>Married Status: </span>{data.married}</p>
                                    <p>  <span className='text-md font-semibold'>How Many Babies :</span>{data.howManyBaby}</p>
                                    <p>  <span className='text-md font-semibold'>Boys :</span>{data.boy}</p>
                                    <p> <span className='text-md font-semibold'>Girls : </span>{data.girl}</p>
                                </div>
                            ) : (
                                <div> <span className='text-md font-semibold'>Married Status : </span>{data.married}</div>
                            )
                        }
                    </div>
                </div>
                <div>
                    <div className='flex items-center'>
                        <div className='w-full bg-black h-[1px]'></div>
                        <div className='w-full max-w-max text-xl font-semibold'>EDUCATIONAL DETAIL</div>
                        <div className='w-full bg-black h-[1px]'></div>
                    </div>
                </div>
                <div className='grid grid-cols-1 md:grid-cols-2 xl:grid-cols-4'>
                    <p> <span className='text-md font-semibold'>Education : </span>{data.education}</p>
                    <p> <span className='text-md font-semibold'>Passing Year : </span>{data.passingYear}</p>
                    <p> <span className='text-md font-semibold'>PanCard Number : </span>{data.panNumber}</p>
                    <p> <span className='text-md font-semibold'>Adhaar Number : </span>{data.adhaarNumber}</p>
                </div>
                <div className='flex items-center'>
                    <div className='w-full bg-black h-[1px]'></div>
                    <div className='w-full max-w-max font-semibold text-xl'>RESIDENTAL DETAIL</div>
                    <div className='w-full bg-black h-[1px]'></div>
                </div>
                <div className='grid grid-cols-1 md:grid-cols-2 xl:grid-cols-3'>
                    <p><span className='text-md font-semibold'>House : </span>{data.house}</p>
                    <p> <span className='text-md font-semibold'>Address : </span>{data.address}</p>
                    <p> <span className='text-md font-semibold'>Pin Code : </span>{data.pinCode}</p>
                    <p> <span className='text-md font-semibold'>Permanent Residental Address : </span>{data.permanentResidentalAddress}</p>
                    <p> <span className='text-md font-semibold'>Permanent Residental Pincode: </span>{data.permanentResidentalPin}</p>
                </div>
                <div className='flex items-center'>
                    <div className='w-full bg-black h-[1px]'></div>
                    <div className='w-full max-w-max font-semibold text-xl'>EMPLOYEMENT DETAIL</div>
                    <div className='w-full bg-black h-[1px]'></div>
                </div>
                <div className='grid grid-cols-1 md:grid-cols-2 xl:grid-cols-3 mb-5'>
                    <p><span className='text-md font-semibold'>Employemnet Salaried : </span>{data.salaried ? "Salaried" : "Self Employeed"}</p>
                    <p> <span className='text-md font-semibold'>Get Salary By : </span>{data.cash && "Cash"}, {data.cheque && "Cheque"}, {data.online && "Online"} </p>
                    <p><span className='text-md font-semibold'>Monthly Income :</span>{data.monthIncome}</p>
                    <p><span className='text-md font-semibold'>Business Name :</span>{data.businessName}</p>
                    <p> <span className='text-md font-semibold'>Registered Add : </span>{data.businessRegisterAdd}</p>
                    <p> <span className='text-md font-semibold'>Pin Code : </span>{data.businessRegisterPin}</p>
                    <p> <span className='text-md font-semibold'>Annual Turn Over: </span>{data.annualTurnOver}</p>
                    <p> <span className='text-md font-semibold'>Registeration Type: </span>{data.registerationType}</p>
                    <p><span className='text-md font-semibold'>Nature Of Buniess :</span>{data.natureBusiness}</p>
                </div>

            </div>

        </div>
    )
}

export default VIewAll